import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import compoundInterestTable from '../../assets/compound_interest_table.png'
import compoundInterestGraph from '../../assets/compound_interest_graph.png'
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CompoundInterestCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const Image = styled.img`
  width: 100%;
`

const CalculatorGrid = styled(ContainerMain)`
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 48px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Year: </strong>
          {payload[0].payload.year}</CardText400>
        <CardText400>
          <strong>Future Value: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.localFutureValue.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CompoundInterestCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ currentAge, setCurrentAge ] = useState(30)
  const [ retirementAge, setRetirementAge ] = useState(67)
  const [ annualSpend, setAnnualSpend ] = useState(30000)
  const [ investmentRate, setInvestmentRate ] = useState(7)
  const [ inflationRate, setInflationRate ] = useState(3)
  const [ currentNetWorth, setCurrentNetWorth ] = useState()
  const [ SWR, setSWR ] = useState(4)
  const [ timeLength, setTimeLength ] = useState(20)
  const [ principal, setPrincipal ] = useState(10000)
  const [ payment, setPayment ] = useState(100)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(12)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)

  const rate = (Math.pow( 1 + ((investmentRate/100)/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const futureValue = principal * Math.pow(1+rate, nper) + payment * ( (Math.pow(1+rate, nper)-1)/ rate )

  const calcCompoundingData = () => {
    let compoundingData = []
    for(let i = 0; i <= timeLength; i++){
      console.log('test')
      const localNper = annualPaymentPeriods * i

      compoundingData.push({
        year: i,
        localFutureValue: principal * Math.pow(1+rate, localNper) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )
      })
    }
    return compoundingData
  }

  const compoundingData = calcCompoundingData()


  return (
    <Layout>
      <SEO
        title={'Compound Interest Calculator with Monthly Contributions'}
        description={'Visualize and calculate how much your money can grow with the power of compound interest'}
        keywords={site.keywords}
      />
      <ReactTooltip />
      <ContainerMain>
        <h1>Compound Interest Calculator with Monthly Contributions</h1>
        <H2>Visualize and calculate how much your money can grow with the power of compound interest</H2>
      </ContainerMain>

      <CalculatorGrid>
        <form>
          <ThemedTextarea
            required
            id="outlined-required"
            label="Initial Investment"
            value={principal}
            onChange={event => setPrincipal(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          <ThemedTextarea
            required
            id="outlined-required"
            label="Length of time in years"
            value={timeLength}
            onChange={event => setTimeLength(event.target.value)}
            variant="outlined"
            fullWidth
          />
          <ThemedTextarea
            id="outlined-name"
            label="Monthly contribution"
            value={payment}
            onChange={event => setPayment(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />

        <Label>
          Interest rate
          <MdHelp size="1.4rem"
              data-tip={`This is your etimated annual interest rate of return for your investment.`}
          />
        </Label>
        <SliderGrid>

          <ThemedSlider
            value={investmentRate}
            aria-labelledby="discrete-slider-always"
            step={0.25}
            min={0}
            max={20}
            marks={[
              {
                value: 0,
                label: '0%'
              },
              {
                value: 7,
                label: '7%'
              },
              {
                value: 20,
                label: '20%'
              }
            ]}
            valueLabelDisplay="auto"
            onChange={ (event, value) => setInvestmentRate(value)}
          />

          <ThemedTextarea
            id="outlined-name"
            label="Rate"
            value={investmentRate}
            onChange={event => setInvestmentRate(event.target.value)}
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            fullWidth
          />
        </SliderGrid>


          <ThemedTextareaPrimary
            id="filled-read-only-input"
            label="End Investment Value"
            value={futureValue.toFixed(0)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
              readOnly: true
            }}
          />

        </form>

          <GraphDesktop>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 50, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="year" label={{value: "Time (years)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                <Legend verticalAlign="top" height={36} />
                <Area type='monotone' name="Investment Value ($)" dataKey='localFutureValue' stroke='#3AC732' fill='#CFF0CD' />
              </AreaChart>
            </ResponsiveContainer>
          </GraphDesktop>

          <GraphMobile>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 10, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="year" label={{value: "Time (years)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  mirror
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                <Legend verticalAlign="top" height={36} />
                <Area type='monotone' name="Investment Value ($)" dataKey='localFutureValue' stroke='#3AC732' fill='#CFF0CD' />
              </AreaChart>
            </ResponsiveContainer>
          </GraphMobile>

      </CalculatorGrid>

      <ContainerTwoCol>
        <ContainerContent>

        <p>Albert Einstein once famously said:</p>
        <blockquote><em>
          "Compound interest is the 8th wonder of the world.  He who understands it, earns it; he who doesn’t, pays it."
        </em>
        </blockquote>

        <p>Einstein couldn't have put it better. Compound interest truly is one of the most powerful forces in the universe,
          and it can both work for you in the case of growing investments or against you in the case of compounding debt.
          The key ingredient for compounding growth is time. You can adjust the inputs in the above calculator to see how when given enough time,
          even a small initial investment can grow to a significant sum of money.</p>

          <h2>Using this calculator</h2>

          <p>
            This interactive calculator makes it easy to calculate and visualize the growth of your investment thanks to compounding interest.
          </p>

          <ul>
            <li><strong>Initial investment</strong> is the starting value of your investment, also known as the principal.</li>
            <li><strong>Length of time in years</strong> is the length of time over which your investment will grow.</li>
            <li><strong>Monthly contribution</strong> is a recurring amount that you contribute to your account each month.</li>
            <li><strong>Interest rate</strong> is the annual interest rate of return of your investment. For reference, the S&P 500 has returned about 7% annually adjusted for inflation since it was started in 1926.</li>
            <li><strong>End investment value</strong> is the final value of your investment at the end of your investing period.</li>
          </ul>

          <h2>How does compound interest work?</h2>
          <p>
            Compound interest is essentially a snowball effect of interest that is accrued on an initial investment of money. To understand how it works, let's start with an example.
            Say you begin with $1,000 and put it into an investment that returns 5% annually. The chart below shows the growth of your investment over the first 10 years.
          </p>

          <Image src={compoundInterestTable} style={{maxWidth: '300px'}}/>

          <p>
            If you look at the change column, you can see that the total account increases by a greater amount each year.
            This is the nonlinear part of exponential growth, where the rate-of-change is increasing. This becomes especially
            powerful over longer periods of time. The element of time is crucially important to wielding the power of compounding growth.
          </p>
          <p>
            While the savings account only grew about $78 year-over-year after the 10th year, or 8% of the initial amount, by the 40th year,
            the savings account will grow $388 year-over-year or about 39% of the initial amount. By year 64, the account will grow by $1,030
            year-over-year, more than the initial $1000 that was put in to start the account! In the first few years, the compounding starts slowly
            and is hard to notice, but over time it becomes immensely powerful.
          </p>

          <Image src={compoundInterestGraph} style={{maxWidth: '800px'}}/>




        {/* <h2>Using this calculator</h2>
        <p>
          This interactive calculator makes it easy to calculate and visualize the growth of your current investments as you plan your journey of Coasting to Financial Independence.
        </p>

        <ul>
          <li>On the left, start by entering your current age and the age you plan to retire.</li>
          <li>
            Then enter the amount you plan to spend annually in retirement.
            Note that in many cases, this number will be less your current spending, because
            (1) you will be covered by Medicare and (2) you may have paid off your primary residence, so you no longer have to pay a mortgage.
          </li>
          <li>
            In the Current Net Worth box, enter the amount that you currently have invested.
            For example, if you have $100,000 invested in the stock market (perhaps through your employer's 401k) and $25,000 saved in your emergency fund, then you should enter $100,000 in this field.
          </li>
          <li>
            Use the sliders to adjust the rates and watch the graph to the right immediately react to your change!
          </li>
          <li>
            <strong>Investment rate of return </strong> is the average return that you expect your investments to grow, not adjusted for inflation.
            This calculator uses 7% as a default Investment rate of return, which is a relatively conservative assumption.
            Historically, the S&P 500 has returned on average 10% annually from its inception in 1926 to 2018.
          </li>
          <li>
            <strong>Inflation rate </strong> is the average annual rate of inflation that you expect to experience in the future.
            Historically, the US economy has experienced an annual average inflation rate of 3%.
          </li>
          <li>
            <strong>Safe Withdrawal Rate </strong> (SWR) is the estimated percentage of your net worth that you expect to withdraw to cover your living expenses in retirement.
            4% is widely considered as the recommended SWR for retirement planning.
            This 4% withdrawal rate was found by <a href="https://www.bogleheads.org/wiki/Trinity_study_update" target="_blank"> the Trinity Study</a> to have a 100% success rate over a 30-year retirement horizon with a 50% / 50% mix of stocks and bonds.
          </li>
        </ul>

        <p>
          The green line on the graph represents the growth of your current net worth over time with no additional contributions.
          The blue line on the graph shows the amount at each age you need to have saved and invested to reach the Coast FIRE milestone.
          You can see how these curves shift relative to each other as you adjust the sliders. If your net worth is greater than your Coast FIRE number, then you have reached Coast FIRE!
        </p>

        <h2>The math behind the calculator</h2>

        <p>
          First, let's recall how to calculate your regular FIRE number:
        </p>
        <p>
          <em>(FIRE number) = (annual spending) / SWR</em>
        </p>
        <p>
          This calculator uses the compound interest formula:
        </p>

        <p>
          <em>A = P * ( 1 + n)^t</em>
        </p>

        <p>
          Here A is the final amount, P is the principle (initial amount), n is the annual growth rate, and t is the time in years.
          We can set our final amount A to be equal to our <em>FIRE number</em> and then solve for P, the initial amount, which is our <em>Coast FIRE number</em>. This gives us:
        </p>
        <p>
          <em>(Coast FIRE number) = (annual spending) / ( SWR * (1 + n)^t )</em>
        </p>

        <h2>But what about inflation?</h2>
        <p>
          Inflation is an important variable to account for when planning for retirement decades in the future.
          It is almost certain that we will experience inflation in the future, and for this reason having your money <u>invested in assets</u> and <u>not</u> all stuffed under your mattress is crucially important.
          Assets like stocks and real estate tend to rise with inflation while cash loses value, meaning the best way to preserve your wealth in times of high inflation is to be invested in these assets.
        </p>

        <p>
          <strong>Don't worry about inflation, it's built in to the calculator!</strong>
          This calculator accounts for inflation by subtracting the inflation rate (from the input slider) from the investment growth rate of return. This gives an <em>inflation-adjusted </em>
          rate of return which is then used to calculate your Coast FIRE number and draw the graph. With this approach, all the numbers in the calculator are adjusted to be in 2020 dollars.
          Think about it like this - you don't have to worry about cost-of-living increases because it's already skimmed off of your expected investment returns.
        </p> */}
        </ContainerContent>
        
        <Sidebar />

      </ContainerTwoCol>
      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'compound-interest-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default CompoundInterestCalc
